/*====================================*\

    @define topics-list;

    This a list of child topics on
    a themes page (used mainly for
    transitions)

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.topics-list {

}

.topics-list__item {
    transition: opacity 0.2s;
    width: 100%;
    height: 200px;
    grid-row: span 1;
    grid-column: span 1;
    margin-bottom: $site-grid-spacing;
    float: right;
    max-width: 66%;

    @supports(display:grid) {
        margin-bottom: 0;
        float: none;
        max-width: 100%;
    }

    &--enter,
    &--appear {
        opacity: 0;
    }

    &--enter-active,
    &--enter-appear {
        opacity: 1;
    }
}
