/*====================================*\

    @define themes-list;

    This a list of  top-level themes on
    the welcome page

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

// This is for gutters. A 0.5em margin here means gutters of 1em (the margins double up).
$themes-list-item-margin: 0.25 * $site-grid-spacing;
$themes-list-item-margin-sm: 0.5 * $site-grid-spacing;
$themes-list-item-margin-lg: 0.75 * $site-grid-spacing;
// Compensates for the margins created by the items.
$themes-list-margin: -$themes-list-item-margin;
$themes-list-margin-sm: -$themes-list-item-margin-sm;
$themes-list-margin-lg: -$themes-list-item-margin-lg;
// This is roughly how wide we want the item to be
$themes-list-flex-basis: 50%;
$themes-list-flex-basis-sm: 34%;
$themes-list-flex-basis-lg: 25%;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.themes-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.themes-list__item {
    transition: all 0.2s ease;
    width: 100%;
    flex: 1 0 $themes-list-flex-basis;
    margin: $themes-list-item-margin;

    &--enter,
    &--appear {
        opacity: 0;
    }

    &--enter-active,
    &--enter-appear {
        opacity: 1;
    }

    &--leave {
        transition: all 0.5s;
        opacity: 0 !important;
    }

    &--leave-active {
        opacity: 0 !important;
    }

    @media($mq-small) {
        flex: 1 0 $themes-list-flex-basis-sm;
        margin: $themes-list-item-margin-sm;
    }

    @media($mq-large) {
        flex: 1 0 $themes-list-flex-basis-lg;
        margin: $themes-list-item-margin-lg;

        // Re-order for intro to appear second
        // &:nth-child(1) {
        //     order: 2;
        // }
        // &:nth-child(2) {
        //     order: 1;
        // }
        // &:nth-child(3) {
        //     order: 3;
        // }
        // &:nth-child(4) {
        //     order: 4;
        // }
        // &:nth-child(5) {
        //     order: 5;
        // }
        // &:nth-child(6) {
        //     order: 6;
        // }
    }
}

.themes-list__item--intro {
    @media($mq-large) {
        flex-basis: $themes-list-flex-basis-lg * 2;
        flex-grow: 2;
        margin: $themes-list-item-margin-lg * 2;
    }
}
