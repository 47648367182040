/*====================================*\

    @define content-wrap;

    Wrapper for the main site content.
    Enforce max-width

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$content-wrap-width: $site-max-width;
$content-wrap-padding: 0.25rem;

/*------------------------------------*\
    Mixins
\*------------------------------------*/

@mixin content-wrap () {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $content-wrap-width;
    position: relative;
    clear: both;
}

/*------------------------------------*\
    Layout
\*------------------------------------*/

.content-wrap {
    @include content-wrap();
}
