/*====================================*\

    @define search;

    This is a page of search results
    containing a number of cards

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.search {
}

.no-results {
    font-family: $font-family-body;
    color: $color-blue;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: $site-grid-spacing;
}

// Colour variations
@each $theme-color, $color-var in $theme-colors {
    .topic--#{$theme-color} {
        // background-color: $color-var;
    }
}
