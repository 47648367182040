/*====================================*\

    @define intro;

    Introductory text box

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$intro-padding: $site-grid-spacing;
$intro-padding-lg: 0;
$intro-color-text: $color-blue;

$intro-underline-height: 0.3rem;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.welcome-container {
    display: inline-block;
    display: flex;
    flex-flow: wrap;

    @supports(display: grid) {
        justify-content: space-around;
    }
}

.intro {
    color: $intro-color-text;
    border-bottom: 5px solid $color-blue;
    padding-bottom: $site-grid-spacing;

    @media($mq-small) {
        border-left: 5px solid $color-blue;
        padding-left: $site-grid-spacing * 2;
        border-bottom: 0;
    }

    @media($mq-medium) {
        padding-left: $site-grid-spacing * 4;
    }
}

// Required because IE doesn't respect box-sizing on flex-basis items
.intro__inner {
    padding: $intro-padding;
    margin-top: $site-grid-spacing*2;

    @media($mq-medium) {
        margin-top: $site-grid-spacing*4;
    }

    @media($mq-large) {
        padding: $intro-padding-lg;
    }
}

.intro__title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 0.5rem;
}

.intro__subtitle {
    font-weight: normal;
}

.intro__body {
    font-family: $font-family-body;
    color: $color-grey;
    font-weight: normal;
    margin-top: 1.4em;

    @media ($mq-small) {
        font-size: $font-size-18;
    }
}
