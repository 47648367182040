/*====================================*\

    @define theme-link;

    A link to a theme page

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$theme-link-padding: $site-grid-spacing * 1.5;
$theme-link-padding-lg: $site-grid-spacing;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.theme-link {
    list-style-type: none;
    font-family: $font-family-heading;
    font-weight: normal;
    font-size: 20px;
    position: relative;

    @media($mq-small) {
        height: 370px;
    }

    @media(min-width: 1100px) {
        max-width: 370px;
        max-height: 370px;
    }
}

// Colour variations
@each $theme-color, $color-var in $theme-colors {
    .theme-link--#{$theme-color} {
        background-color: $color-var;
        opacity: 0.9;
        background-blend-mode: multiply;

        .theme-link__link {
            background-color: $color-var;
        }

        @supports(display:grid) {
            .theme-link__link {
                background-color: transparent;
            }
        }
    }

    .theme-link__overlay--#{$theme-color} {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $color-var;
    }
}

.theme-link__link {
    color: $color-white;
    text-decoration: none;
    padding: $theme-link-padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    position: relative;

    @media ($mq-small) {
        flex-direction: column;

        &:hover,
        &:focus,
        &:active {
            .theme-link__icon {
                transform: rotate(10deg);
            }
        }
    }
}

.theme-link__icon {
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.theme-link__text {
    width: 60%;
    max-width: 10rem;
    line-height: 1;
    padding-top: 0.5rem;
    font-size: 1.15rem;
    margin-top: 0.5rem;
    transition: all 0.3s ease;
    transform-origin: top left;
    text-transform: uppercase;
    font-family: $font-family-heading;
    position: relative;
    margin-left: $site-grid-spacing;


    @media ($mq-small) {
        width: 85%;
        max-width: none;
        padding-top: 0.75rem;
        font-size: 1.4rem;
        margin-top: auto;
        max-width: 18rem;
    }

    @media(min-width: 768px) {
        &::before {
            content: '';
            position: absolute;
            bottom: -24px;
            left: -16px;
            border-left: 1px solid $color-white;
            height: 80px;
        }
    }
}
