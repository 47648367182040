/*====================================*\

    @define cards;

    This a list of cards on
    a topic page (used mainly for
    transitions)

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.cards-list {

}

.cards-list__item {
    transition: opacity 0.2s;

    &--enter,
    &--appear {
        opacity: 0;
    }

    &--enter-active,
    &--enter-appear {
        opacity: 1;
    }
}
