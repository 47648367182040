/*====================================*\

    @define utilities;

    Hide only visually, but have it available for screen readers:
    http://snook.ca/archives/html_and_css/hiding-content-for-accessibility

    via H5BP

\*====================================*/

.u-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


/*
 * Extends the .u-visually-hidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.u-visually-hidden.is-focusable:active,
.u-visually-hidden.is-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
