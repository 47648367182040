/*====================================*\

    @define summary;

    A 'card' like element displaying
    category title and description

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$bgColor: #F4FAFF;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.login__welcome-message {
    margin-bottom: $site-grid-spacing;
    color: $color-blue;

    h2 {
        font-size: 40px;
    }
}

// For components where the bg could be more than one colour, it will have to loop through the colours
// And add a modifier to the bg element e.g.: --orange etc
.login-bg {
    background: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="222" height="217">\
    <path fill="none" d="M-1-1h160v174H-1z"/><g><g fill-rule="evenodd" fill="none"><path fill="#{url-encode-color($bgColor)}" d="M83.195 87.983c3.148 1.985 4.103 6.147 2.13 9.279-1.373 2.177-3.846 3.342-6.456 3.047a3.355 3.355 0 0 0-3.405 1.863l-7.651 15.798-5.042-3.179 10.954-13.715a3.355 3.355 0 0 0 .215-3.876c-1.394-2.227-1.408-4.961-.035-7.138 1.973-3.13 6.142-4.064 9.29-2.08m-16.087 10.56L55.15 113.52a3.353 3.353 0 0 0-.68 2.655A3.383 3.383 0 0 0 56 118.46l11.42 7.2a3.38 3.38 0 0 0 2.724.395 3.348 3.348 0 0 0 2.103-1.76l8.356-17.246c4.257-.271 8.117-2.517 10.431-6.188 3.95-6.265 2.04-14.589-4.258-18.56-6.298-3.97-14.632-2.104-18.582 4.16-2.314 3.672-2.677 8.122-1.086 12.08"/>\
    <path mask="url(%23b)" fill="#{url-encode-color($bgColor)}" d="M143.042 142.683a6.667 6.667 0 0 1-6.668 6.668H43.317a6.667 6.667 0 0 1-6.667-6.668V62.925c0-.01.008-.018.018-.018h106.356c.01 0 .018.008.018.018v79.758zm-26.616-86.426h-53.16a.018.018 0 0 1-.018-.017V43.325c0-14.935 12.143-27.225 27.075-26.96 14.447.255 26.12 12.087 26.12 26.593V56.24c0 .01-.007.017-.017.017zM49.948 42.958c0-22.07 18.012-40.012 40.11-39.896 22.026.115 39.685 18.326 39.685 40.353V56.24c0 .01-.008.017-.018.017h-6.615a.018.018 0 0 1-.018-.017V43.35c0-18.656-15.187-33.963-33.841-33.634-18.059.318-32.654 15.109-32.654 33.242V56.24c0 .01-.008.017-.018.017h-6.614a.018.018 0 0 1-.018-.017V42.958h.001zm96.399 13.3h-9.938a.018.018 0 0 1-.018-.018V43.426c.001-25.739-20.693-46.952-46.431-47.014C64.241-3.65 43.3 17.254 43.3 42.958V56.24c0 .01-.008.017-.018.017h-9.939A3.342 3.342 0 0 0 30 59.6v83.083C30 150.038 35.962 156 43.317 156h93.057c7.355 0 13.317-5.962 13.317-13.317V59.6a3.342 3.342 0 0 0-3.343-3.343l-.001.001z" transform="rotate(32.229 89.845 76.206)"/></g></g>\
    </svg>');
    background-size: 100px 100px;

    .hero__icon-box {
        width: 100%;
        height: 350px;
    }
}

.login-form {
    background-color: $color-blue;
    position: relative;
    color: $color-white;
    padding: $site-grid-spacing*3 0 0 $site-grid-spacing*6;
    height: 350px;

    @media ($mq-small) {
        &::before {
            content: '';
            width: 2px;
            height: 90%;
            position: absolute;
            bottom: 0px;
            left: $site-grid-spacing * 3;
            background-color: $color-white;
            z-index: 2;
        }
    }
}

.login-form h1 {
    margin-bottom: $site-grid-spacing;
}

.login-form__label {
    text-transform: uppercase;
    font-family: $font-family-heading;
    font-size: 21px;
    display: block;
}

.login-form__input {
    appearance: none;
    background-color: transparent;
    border: none;
    border-bottom: solid 4px $color-white;
    margin-bottom: $site-grid-spacing;
    width: 50%;

    &:focus {
        background-color: $color-white;
        color: $color-black;
    }
}

.login-form__submit {
    background-color: transparent;
    border: 1px solid $color-white;
    color: $color-white;
    font-style: italic;
    padding: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: $color-white;
        color: $color-blue;
    }
}

.login-form__submit__arrow {
    margin-left: $site-grid-spacing/2;
}


