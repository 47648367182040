/*====================================*\

    @define card;

    This is a card representing a single entry

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$card-padding-v: 1.5rem;
$card-padding-h: 1.5rem;

$card-arrow-underline-height: 0.25rem;
$card-arrow-padding: 0.3rem;

$card-icon-height: 8 * $site-grid-spacing;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.card {
    background-color: $color-white;
    position: relative;
    color: $color-black;
    padding: $site-grid-spacing * 2 $site-grid-spacing * 2 $site-grid-spacing * 2 $site-grid-spacing * 3.5;
    margin-bottom: $site-grid-spacing;

    @supports(display:grid) {
        margin-bottom: 0;
    }

    @media($mq-small) {
        padding: $site-grid-spacing * 2 $site-grid-spacing * 6 $site-grid-spacing * 2 $site-grid-spacing * 3.5;
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 25px;
        top: 0;
        left: 0;
        display: block;
        z-index: 1;
        transition: width 0.35s ease-out;
    }
}

.card__title {
    font-family: $font-family-body;
    margin-bottom: $site-grid-spacing;
}

.card__body {
    margin-bottom: $site-grid-spacing;
}

// Link lists

.card__link-list {
    list-style-type: none;
}

.card__link-list-link {
    text-decoration: none;
    position: relative;
}

.card__link-text {
    font-style: italic;
}

.card__link-arrow {
    position: absolute;
    top: 1px;
    right: -15px;
}

@each $theme-color, $color-var in $theme-colors {
    .card__link-arrow--#{$theme-color} {
        stroke: #{$color-var};
    }
}

// Download card

.card__link--button {
    appearance: none;
    border: 0;
    font-style: italic;
    position: relative;
    padding: $site-grid-spacing/2 0;
    cursor: pointer;
    background-color: transparent;
}

.card--download button p,
.card--download button svg {
    display: inline-block;
}

.card--download button p {
    margin-right: 10px;
}

.card--download button svg {
    position: relative;
    top: 5px;
}

@each $theme-color, $color-var in $theme-colors {
    .download__icon--#{$theme-color} g {
        fill: #{$color-var};
    }
}

// Quote

.card--quote {
    margin: $site-grid-spacing $site-grid-spacing/2;

    @media($mq-medium) {
        background-color: transparent;
        border: 0;
        color: $color-green;
        font-family: $font-family-heading;
        padding: 0;
        position: relative;

        &::before {
            content: '';
            height: 0%;
            width: 0;
        }

        .card__title {
            display: none;
        }
    }
}

.quote__body {
    @media ($mq-medium) {
        font-size: $font-size-32;
    }
}

.quote__cite {
    @media ($mq-medium) {
        font-family: $font-family-body;
        color: $color-black;
        font-size: $font-size-18;
        font-style: normal;
    }
}

.quote__icon {
    position: absolute;
    top: -40px;
    left: 0;
    display: none;

    @media($mq-medium) {
        display: block;
    }
}

@each $theme-color, $color-var in $theme-colors {
    .quote__icon--#{$theme-color} {
        fill: #{$color-var};
    }
}

.grid--third--left .card--quote {
    display: none;

    @media($mq-small) {
        display: block;
    }
}

// Video

// Responsive video wrapper
.card__video-wrap {
    position: relative;
    padding-bottom: 76.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-top: $site-grid-spacing * 1.5;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-width: 640px;
        max-height: 360px;
    }

    @media(min-width: 720px) {
        padding-bottom: 360px;
    }
}

// Question

.card--question {
    .card__title {
        color: $color-green;
    }
}

// Section header

.card--sectionHeader {
    position: relative;

    h3 {
        font-family: $font-family-heading;
        font-size: 40px;
        margin-bottom: -16px;
        letter-spacing: 1px;
    }

    &::before {
        content: '';
        height: 74%;
        position: absolute;
        left: 34px;
        top: auto;
        bottom: 0;
    }
}

// ------------------ //
// COLOUR VARIATIONS
// ------------------ //

@each $theme-color, $color-var in $theme-colors {
    .card--#{$theme-color} {
        border: 4px solid #{$color-var};

        .card__link-text {
            color: #{$color-var};
        }

        .card__link--button {
            color: #{$color-var};
        }

        &.card--quote {
            @media($mq-medium) {
                color: #{$color-var};
                border: none;
            }
        }

        &.card--question {
            .card__title {
                color: #{$color-var};
            }
        }

        &.card--sectionHeader {
            h3 {
                color: $color-var;
            }

            p {
                color: $color-var;
            }

            &::before {
                border-left: 2px solid $color-var;
            }
        }
    }
}

@each $theme-color, $color-var in $bg-theme-colors {
    .card--#{$theme-color} {
        &.card--sectionHeader {
            background-color: darken($color-var, 3);
        }
    }
}

