/*====================================*\

    SETTINGS - LAYOUT

\*====================================*/

/*------------------------------------*\
    Site constants
\*------------------------------------*/

$site-max-width: 1200px;
$site-grid-spacing: 1rem;
$site-border-radius: 6px;

$site-header-height: 160px;
