/*====================================*\

    @define button;

    A plain oblong-shaped element for clicking

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$button-padding-v: $site-grid-spacing / 2;
$button-padding-h: $site-grid-spacing;
$button-color-text: $color-white !default;
$button-border-width: 0.125rem !default;
$button-border-radius: 4px !default;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.button {
    color: $button-color-text;
    text-decoration: none;
    padding: $button-padding-v $button-padding-h;
    border: 0;
    border-radius: $button-border-radius;
    display: inline-block;
    transition: background-color 0.3s ease;

    .icon {
        margin-left: 1rem;
    }
}

.button--outline {
    border: $button-border-width solid $color-white;
    padding: $button-padding-v - $button-border-width $button-padding-h - $button-border-width;
    background: transparent;
    color: $color-white;
    transition: all 0.3s ease;

    &:hover {
        cursor: pointer;
        background-color: $color-white;
        color: $color-purple;
    }
}

.button--arrow-right {
    font-weight: bold;
}

// Colour variations
@each $theme-color, $color-var in $theme-colors {
    .button--#{$theme-color} {
        background-color: $color-var;

        &:hover,
        &:focus {
            background-color: tint($color-var, 20);
        }
    }
}
