/*------------------------------------*\
    HEADINGS
\*------------------------------------*/

/**
 * Headings 1–6.
 */

// Predefine the variables below in order to alter and enable specific features.
$heading-size-1: 48px !default;
$heading-size-2: 30px !default;
$heading-size-3: 24px !default;
$heading-size-4: 20px !default;
$heading-size-5: 16px !default;
$heading-size-6: 14px !default;

h1,
.h1 {
    font-size: $heading-size-1;
}

h2,
.h2 {
    font-size: $heading-size-2;
}

h3,
.h3 {
    font-size: $heading-size-3;
}

h4,
.h4 {
    font-size: $heading-size-4;
}

h5,
.h5 {
    font-size: $heading-size-5;
}

h6,
.h6 {
    font-size: $heading-size-6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-heading;
    font-weight: bold;
}
