/*====================================*\

    @define search-bar;

    Form to search the site

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.search-container {
    display: inline-block;
    display: flex;
    margin-bottom: $site-grid-spacing*1.5;
    flex-direction: column;

    @media($mq-small) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.search-bar {
    font-family: $font-family-heading;
    position: relative;
    font-size: 18px;
    margin-top: 16px;
    width: 42%;

    @media($mq-small) {
        width: 25%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 30px;
        left: 0;
        border-bottom: solid 4px $color-white;
        width: 100%;
    }
}

.search-bar__input {
    margin-right: $site-grid-spacing/2;
    background-color: transparent;
    border: 0;
    color: $color-white;
    text-transform: uppercase;
    width: 250px;
}

.search__button {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 8px;
    top: 0;
    color: $color-white;
}
