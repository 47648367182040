/*====================================*\

    @define header;

    Top-bar

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$header-padding: $site-grid-spacing / 2;
$header-nav-stripe-height: 0.2rem;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.header {
    background-color: $color-blue-grey;
    color: $color-white;
    position: fixed;
    width: 100%;
    z-index: 20;
    min-height: $site-header-height;
    padding: $header-padding;
    margin-bottom: $site-grid-spacing;

    @media($mq-small) {
        padding: $header-padding $header-padding * 2;
    }

    @media($mq-large) {
        padding: 1rem;

        .content-wrap {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

// Give main content a padding top to allow space for sticky nav
main {
    padding-top: 195px;

    @media($mq-large) {
        padding-top: 225px;
    }
}

.header__back-link {
    display: inline-block;
    color: $color-white;
    border-radius: 50%;
    border: 2px solid $color-white;
    text-decoration: none;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 26px;
    line-height: 50px;
    transition: all 0.3s ease;

    .icon {
        position: relative;
        right: 2px;
    }

    &:hover {
        background-color: $color-white;
        color: $color-purple;
    }

    @media ($mq-large) {
        display: none;
    }
}

.header__home-link {
    display: inline-block;
    color: $color-white;
    height: 100%;
    text-decoration: none;
    float: right;

    @media ($mq-large) {
        float: none;
    }
}

.header__logo {
    display: block;
    height: 50px;

    @media ($mq-large) {
        height: 92px;
    }
}

.header__nav {
    display: none;

    @media ($mq-large) {
        display: block;
    }
}

.header__nav-list {
    list-style-type: none;
}

.header__nav-item {
    display: inline-block;
    text-align: center;
    padding-left: $site-grid-spacing / 2;
    padding-right: $site-grid-spacing*1.5;
    margin-bottom: $site-grid-spacing/2;
}

.header__nav-link {
    position: relative;
    display: block;
    color: $color-white;
    text-decoration: none;
    font-family: $font-family-heading;
    font-size: 18px;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 5px;

    &:hover,
    &:focus,
    &:active {
        &::after {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: $header-nav-stripe-height;
            background-color: $color-white;
            opacity: 1;
            transition: all 0.2s ease-in-out;
        }
    }
}

// Colour variations
@each $theme-color, $color-var in $theme-colors {
    .header__nav-link--#{$theme-color} {
        &::after {
            background-color: $color-var;
        }

        &:hover {
            &::after {
                background-color: tint($color-var, 20);
            }
        }
    }
}

