/*====================================*\

    SETTINGS - COLOURS

\*====================================*/

/*------------------------------------*\
    Colour definitions
\*------------------------------------*/

$color-black: #000;
$color-white: #fff;

$color-pantone-black-7: #3c3c3b;

$color-blue: #0a2d50;
$color-grey: #232323;
$color-green: #019c7c;
$color-purple: #7727bd;
$color-orange: #de6a15;
$color-blue-light: #6284a5;
$color-blue-grey: #455259;
$color-green-light: #c7e9e2;

$color-purple--bg: #fdfaff;
$color-green--bg: #f0fcf9;
$color-orange--bg: #fffaf7;
$color-blue--bg: #fbfbfb;

/*------------------------------------*\
    Global Colour uses
\*------------------------------------*/

$color-text: $color-pantone-black-7;

$theme-colors: (
    'purple'      : $color-purple,
    'orange'      : $color-orange,
    'blue'        : $color-blue,
    'green'       : $color-green
);

$bg-theme-colors: (
    'purple'      : $color-purple--bg,
    'orange'      : $color-orange--bg,
    'blue'        : $color-blue--bg,
    'green'       : $color-green--bg
);
