/*====================================*\

    @define view;

    The main content wrapper

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.view-wrap {
    transition: all 0.5s;
}

.view {
    &--enter,
    &--appear {
        opacity: 0;
    }

    &--enter-active,
    &--appear-active {
        opacity: 1;
    }

    &--leave {
        transition: all 0.5s;
        opacity: 0 !important;
    }

    &--leave-active {
        opacity: 0 !important;
    }
}
