/*====================================*\

    @define grid;

    This is the main layout grid.
    Based on https://github.com/Heydon/fukol-grids

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

// This is for gutters. A 0.5em padding here means gutters of 1em (the padding doubles up).
// $grid-item-padding: 0.25 * $site-grid-spacing;
// $grid-item-padding-sm: 0.5 * $site-grid-spacing;
// $grid-item-padding-lg: 0.75 * $site-grid-spacing;

/*------------------------------------*\
    Layout
\*------------------------------------*/

.grid {
    display: grid;
    // Columns at min width 326px and max width 1fr?
    // Auto fit will make them stretch to fill the space until there
    // is room for another at 326px
    grid-template-columns: repeat(auto-fit, minmax(326px, 1fr));
    grid-gap: $site-grid-spacing;
}

.grid-item--third--left {
    grid-column: 1 / span 3;

    @media ($mq-medium) {
        grid-column: 1 / span 1;
    }
}

.grid-item--two-thirds {
    grid-column: 1 / span 3;

    @media($mq-small) {
        grid-column: 2 / span 2;
    }
}

/*------------------------------------*\
    Modifiers
\*------------------------------------*/

// .grid--search {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: auto;
//     grid-gap: $site-grid-spacing;

//     @media($mq-small) {
//         grid-template-columns: 1fr 1fr 1fr;
//         grid-template-rows: 370px auto;
//     }
// }