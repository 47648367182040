/*====================================*\

    @define topic;

    This is a second level category
    containing a number of cards

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.topic {
}

.topic__title {
    color: $color-green;
}

// Colour variations
@each $theme-color, $color-var in $theme-colors {
    .topic--#{$theme-color} {
        // background-color: $color-var;
    }
}
