/*====================================*\

    @define summary;

    A 'card' like element displaying
    category title and description

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$hero-padding: 1.5 * $site-grid-spacing;
$hero-padding-lg: 2.5 * $site-grid-spacing;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.hero {
    font-weight: normal;
    font-size: 1rem;
    color: $color-white;
    min-height: 200px;
    height: auto;
    position: relative;
    padding-top: $site-grid-spacing;
    margin-bottom: $site-grid-spacing;
    display: inline-block;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media($mq-small) {
        padding-top: 0;
        flex-direction: row;
        height: 370px;
    }
}

.hero__icon-box {
    height: 100%;
    width: calc(33% - 8px);
    display: none;
    justify-content: center;
    align-items: center;

    @media ($mq-small) {
        display: flex;
    }
}

.hero__icon {
    width: 133px;
}

.hero__title-box {
    width: 100%;
    height: 100%;
    padding: $site-grid-spacing * 2;
    min-height: 200px;
    height: auto;

    @media($mq-small) {
        width: calc(67% - 8px);
        height: 100%;
    }
}

.hero__title {
    color: $color-white;
    padding-top: 0.5rem;
    line-height: 2.5rem;
    font-size: 2.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
    text-transform: uppercase;
    font-weight: normal;
}

.hero__description {
    font-family: $font-family-body;
    font-size: $font-size-18;

    &:empty {
        display: none;
    }
}

// Colour variations
.hero__icon-box--green {
    background-color: $color-green;
}

.hero__icon-box--green-outline {
    border: 4px solid $color-green;
}

.hero__icon-box--blue-outline {
    border: 4px solid $color-blue;
}

.hero__icon-box--white-bg {
    background-color: $color-white;
}

.summary--green {
    background-color: $color-green;
}

.summary--white {
    color: $color-text;

    .summary__title {
        color: $color-text;
    }
}

/*------------------------------------*\
    Theme page-specific css
\*------------------------------------*/

.theme,
.search {
    .hero__title-box {
        position: relative;

        @media ($mq-small) {
            &::before {
                content: '';
                width: 2px;
                height: 70%;
                position: absolute;
                bottom: 0px;
                left: $site-grid-spacing * 3.5;
                background-color: $color-white;
                z-index: 2;
            }

            .hero__description {
                margin-left: $site-grid-spacing * 3.5;
            }
        }

    }

    .hero__title {


        @media($mq-small) {
            margin-left: $site-grid-spacing * 3.5;
            margin-top: $site-grid-spacing * 5.6;
        }
    }
}

.hero__title-box__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;
}

// Colour variations
@each $theme-color, $color-var in $theme-colors {
    .hero__icon-box--#{$theme-color} {
        background-color: $color-var;
    }
}

/*------------------------------------*\
    Topic page-specific css
\*------------------------------------*/

.topic {
    .hero__title-box {
        position: relative;

        @media ($mq-small) {
            &::before {
                content: '';
                width: 2px;
                height: 90%;
                position: absolute;
                bottom: -20px;
                left: $site-grid-spacing * 3.5;
                background-color: $color-white;
                z-index: 2;
            }
        }
    }

    .hero__title {
        font-size: 2rem;
        line-height: 2rem;

        @media($mq-small) {
            margin-left: $site-grid-spacing * 3.5;
            margin-top: $site-grid-spacing * 1;
        }

        @media($mq-medium) {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }

    .hero__description {
        @media($mq-small) {
            margin-left: $site-grid-spacing * 3.5;
        }
    }
}

.hero__icon-box--topic {
    // margin: $site-grid-spacing / 2;
    background-color: $color-white;
}

.hero__title--topic {
    background-color: $color-green;
}

//Colour variations
@each $theme-color, $color-var in $theme-colors {
    .hero--#{$theme-color} {
        background-color: #{$color-var};
    }

    .hero__icon-box--topic--#{$theme-color} {
        border: 4px solid #{$color-var};
        color: #{$color-var};
    }
}

/*------------------------------------*\
    Search page-specific css
\*------------------------------------*/

.search {
    .hero__title-box {
        background-color: $color-blue;
    }

    .hero__description {
        text-transform: uppercase;
        font-size: 28px;
        font-family: $font-family-heading;

        @media($mq-small) {
            margin-left: $site-grid-spacing * 3.5;
        }
    }
}
