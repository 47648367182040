/*====================================*\

    @define theme;

    This is a top level category listing
    page, containing a list of child themes

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.theme {
    color: $color-blue;
}

.theme__icon {
}

.theme__topics {
    margin-top: $site-grid-spacing;
    // Clearfix
    &::before {
        content: ' '; /* 1 */
        display: table; /* 2 */
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }

    /* hasLayout fix for < IE8 */
    .lt-ie8 & {
        zoom: 1;
    }

    @supports(display:grid) {
        &::before {
            content: ' '; /* 1 */
            display: none; /* 2 */
        }

        &::after {
            content: '';
            display: none;
            clear: none;
        }
    }
}
