/*====================================*\

    SETTINGS - FONTS

\*====================================*/

/*------------------------------------*\
    Font stacks
\*------------------------------------*/

$font-family-body: Helvetica, Calibri, Arial, sans-serif;
$font-family-heading: 'BureauGrotesque-ThreeSeven', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !default;

/*------------------------------------*\
    Font sizes
\*------------------------------------*/

$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;
$font-size-40: 2.5rem;
$font-size-48: 3rem;

$font-size-normal: 16px;
$font-size-root: $font-size-normal !default;

/*------------------------------------*\
    Line heights
\*------------------------------------*/

$line-height-multiplier: 1.4 !default;

$line-height-normal: $font-size-normal * $line-height-multiplier;

/*------------------------------------*\
    Font face rules
\*------------------------------------*/

@font-face {
    font-family: 'BureauGrotesque-ThreeSeven';
    src: url('/fonts/bureaugrotesque-threeseven.woff') format('woff');
    font-style: normal;
    font-weight: bold;
}
