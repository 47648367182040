/*====================================*\

    @define footer;

    Site footer

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$footer-stripe-height: 0.3rem;

/*------------------------------------*\
    Mixins
\*------------------------------------*/



/*------------------------------------*\
    Styles
\*------------------------------------*/

.footer {
    background-color: $color-blue;
    color: $color-white;
    padding-left: $site-grid-spacing;
    padding-right: $site-grid-spacing;
    margin-top: $site-grid-spacing;
}

.footer__col {
    display: inline-block;
    width: 100%;
    float: left;
    padding-top: $site-grid-spacing;
    padding-bottom: $site-grid-spacing + $footer-stripe-height;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: $footer-stripe-height;
        border-radius: $footer-stripe-height / 2;
        background-color: $color-white;
    }

    @media($mq-small) {
        width: 33.3%;
        margin-top: $site-grid-spacing;
        margin-bottom: $site-grid-spacing;
        padding: $site-grid-spacing;

        &::after {
            display: none;
        }
    }
}

.footer__links {
    padding: $site-grid-spacing;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media($mq-small) {
        flex-direction: row;
    }
}

.footer__links__link {
    color: $color-white;
    padding-right: 10px;
    text-align: center;
}

.footer__links__link:not(:last-child) {
    @media($mq-small) {
        border-right: solid 1px $color-white;
        margin-right: 10px;
    }
}

.footer__logo-wrap {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__logo {
    max-height: 2.5 * $site-grid-spacing;
    margin-top: 4 * $site-grid-spacing;
    margin-right: 1.5 * $site-grid-spacing;
    margin-bottom: 2 * $site-grid-spacing;
    margin-left: 1.5 * $site-grid-spacing;

    @media($mq-small) {
        max-height: 4 * $site-grid-spacing;
    }
}

.footer__legal-text {
    display: inline-block;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: $site-grid-spacing;
    padding-bottom: $site-grid-spacing;
    font-size: $font-size-14;

    @media($mq-small) {
        padding: 1rem 1rem $site-grid-spacing * 3 1rem;
        border-top: 1px solid $color-white;
    }
}
