/*====================================*\

    @define tpoc-link;

    A link to a topic page

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$topic-link-padding: $site-grid-spacing * 1.5;
$topic-link-padding-lg: $site-grid-spacing * 2;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.topic-link {
    list-style-type: none;
    font-family: $font-family-heading;
    font-weight: normal;
    font-size: $font-size-20;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: $topic-link-padding $topic-link-padding ($topic-link-padding / 2) $topic-link-padding;
    display: inline-block;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $color-white;
    transition: background-color 0.3s ease;

    @media($mq-medium) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &:hover::before {
        width: 100%;
    }
}

// Colour variations
@each $theme-color, $color-var in $theme-colors {
    .topic-link--#{$theme-color} {
        color: $color-var;
        border: 2px solid $color-var;

        .topic-link__text {
            color: $color-var;
        }

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 20px;
            top: 0;
            left: 0;
            background-color: tint($color-var, 70);
            display: block;
            z-index: 1;
            transition: width 0.35s ease-out;
        }

    }
}

.topic-link__link {

}

.topic-link__text {
    line-height: 1.3;
    padding-right: 0.5rem;
    font-size: 1.25rem;
    min-height: ((1.15rem * 3) * 1.3) + $brand-line-height + 0.5rem;
    text-transform: uppercase;
    font-weight: normal;
    width: 55%;
    margin-left: $site-grid-spacing;
    z-index: 2;
}

.topic-link svg {
    height: 90px;
    width: 90px;
    position: absolute;
    right: $site-grid-spacing * 3;
    top: 50px;
    z-index: 2;

    @media ($mq-small) {
        right: $site-grid-spacing * 1.5;
    }
}

.topic-link__icon {
    width: 90px;
    height: 90px;
}
