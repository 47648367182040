/*====================================*\

    @define utilities;

    Display none above a specific
    breakpoint

\*====================================*/

.u-mq-hide {}

.u-mq-hide-sm {
    @media($mq-small) {
        display: none;
    }
}

.u-mq-hide-md {
    @media($mq-medium) {
        display: none;
    }
}

.u-mq-hide-lg {
    @media($mq-large) {
        display: none;
    }
}
